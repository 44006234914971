<template>
  <nav class="nav">
    <ul class="nav-list">
      <li class="nav-list__item">
        <router-link
          :to="{
            name: 'CreditCard',
            params: {
              stationId: this.$route.params.stationId,
              connectorId: this.$route.params.connectorId,
            },
          }"
          active-class="active"
          class="router-link"
          >信用卡</router-link
        >
      </li>
      <!-- <li class="nav-list__item">
        <router-link
          :to="{
            name: 'GooglePay',
            params: {
              stationId: this.$route.params.stationId,
              connectorId: this.$route.params.connectorId
            }
          }"
          active-class="active"
          class="router-link"
          >GooglePay</router-link
        >
      </li> -->
    </ul>
  </nav>
</template>
<script>
export default {
  name: "PaymentNav.vue",
};
</script>
