<template>
  <main id="charge">
    <section class="section-top navTypes">
      <h1 class="section-top-title">選擇付款方式</h1>
    </section>
    <PaymentNav />
    <router-view></router-view>
  </main>
</template>
<script>
import PaymentNav from "../components/UI/Nav/PaymentNav.vue";
export default {
  name: "Payment",
  components: {
    PaymentNav,
  },
};
</script>
